@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

@font-face {
  font-family: Spantran;
  src: url('../fonts/Spantaran.ttf');
}
@font-face {
  font-family: TrenchThin;
  src: url('../fonts/LabilGrotesk-Medium.ttf');
}
@font-face {
  font-family: ManropeLight;
  src: url('../fonts/Manrope-Light.ttf');
}
@font-face {
  font-family: ManropeRegular;
  src: url('../fonts/Manrope-Regular.ttf');
}
@font-face {
  font-family: ManropeMedium;
  src: url('../fonts/Manrope-Medium.ttf');
}
@font-face {
  font-family: ManropeSemiBold;
  src: url('../fonts/Manrope-SemiBold.ttf');
}

:root {
  --font-family-body: "Poppins", sans-serif;
  --font-family-heading: "Poppins", sans-serif;
  --primary-color: #001247;
  --secondary-color: #131129;
  --body-text-color: #c9c9c9;
  --heading-text-color: #fff;
  --paragraph-text-color: #c9c9c9;
  --nft-gradient-color: linear-gradient(
    79deg,
    rgba(32, 149, 229, 1) 0%,
    rgba(158, 196, 239, 1) 100%
  );
  --reverse-nft-gradient-color: linear-gradient(
    79deg,
    rgba(158, 196, 239, 1) 100%,
    rgba(32, 149, 229, 1) 0%
  );
  --gradient-btn-hover: linear-gradient(
    90deg,
    rgba(32, 149, 229, 1) 0%,
    rgba(158, 196, 239, 1) 100%
  );
  --sidebar-icon-background: linear-gradient(
    rgb(0, 18, 71) 0%,
    rgba(19, 17, 41, 0) 100%
  );
}

body {
  font-family: "ManropeRegular", sans-serif;
}
option {
  color: black;
}
.overlay {
  background: #ffffff;
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: .80;
}
.spinner {
    margin: 0 auto;
    height: 64px;
    width: 64px;
    animation: rotate 0.8s infinite linear;
    border: 5px solid firebrick;
    border-right-color: transparent;
    border-radius: 50%;
}
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.box.box-primary {
  border-top-color: #3c8dbc;
}
.box.box-info {
  border-top-color: #00c0ef;
}
.box.box-danger {
  border-top-color: #dd4b39;
}
.box.box-warning {
  border-top-color: #f39c12;
}
.box.box-success {
  border-top-color: #00a65a;
}
.box.box-default {
  border-top-color: #d2d6de;
}
.box.collapsed-box .box-body,
.box.collapsed-box .box-footer {
  display: none;
}
.box .nav-stacked > li {
  border-bottom: 1px solid #f4f4f4;
  margin: 0;
}
.box .nav-stacked > li:last-of-type {
  border-bottom: none;
}
.box.height-control .box-body {
  overflow: auto;
}
.box .border-right {
  border-right: 1px solid #f4f4f4;
}
.box .border-left {
  border-left: 1px solid #f4f4f4;
}
.box.box-solid {
  border-top: 0;
}
.box.box-solid > .box-header .btn.btn-default {
  background: transparent;
}
.box.box-solid > .box-header .btn:hover,
.box.box-solid > .box-header a:hover {
  background: rgba(0, 0, 0, 0.1);
}
.box.box-solid.box-default {
  border: 1px solid #d2d6de;
}
.box.box-solid.box-default > .box-header {
  color: #444;
  background: #d2d6de;
  background-color: #d2d6de;
}
.box.box-solid.box-default > .box-header a,
.box.box-solid.box-default > .box-header .btn {
  color: #444;
}
.box.box-solid.box-primary {
  border: 1px solid #3c8dbc;
}
.box.box-solid.box-primary > .box-header {
  color: #fff;
  background: #3c8dbc;
  background-color: #3c8dbc;
}
.box.box-solid.box-primary > .box-header a,
.box.box-solid.box-primary > .box-header .btn {
  color: #fff;
}
.box.box-solid.box-info {
  border: 1px solid #00c0ef;
}
.box.box-solid.box-info > .box-header {
  color: #fff;
  background: #00c0ef;
  background-color: #00c0ef;
}
.box.box-solid.box-info > .box-header a,
.box.box-solid.box-info > .box-header .btn {
  color: #fff;
}
.box.box-solid.box-danger {
  border: 1px solid #dd4b39;
}
.box.box-solid.box-danger > .box-header {
  color: #fff;
  background: #dd4b39;
  background-color: #dd4b39;
}
.box.box-solid.box-danger > .box-header a,
.box.box-solid.box-danger > .box-header .btn {
  color: #fff;
}
.box.box-solid.box-warning {
  border: 1px solid #f39c12;
}
.box.box-solid.box-warning > .box-header {
  color: #fff;
  background: #f39c12;
  background-color: #f39c12;
}
.box.box-solid.box-warning > .box-header a,
.box.box-solid.box-warning > .box-header .btn {
  color: #fff;
}
.box.box-solid.box-success {
  border: 1px solid #00a65a;
}
.box.box-solid.box-success > .box-header {
  color: #fff;
  background: #00a65a;
  background-color: #00a65a;
}
.box.box-solid.box-success > .box-header a,
.box.box-solid.box-success > .box-header .btn {
  color: #fff;
}
.box.box-solid > .box-header > .box-tools .btn {
  border: 0;
  box-shadow: none;
}
.box.box-solid[class*="bg"] > .box-header {
  color: #fff;
}
.box .box-group > .box {
  margin-bottom: 5px;
}
.box .knob-label {
  text-align: center;
  color: #333;
  font-weight: 100;
  font-size: 12px;
  margin-bottom: 0.3em;
}
.box > .overlay,
.overlay-wrapper > .overlay,
.box > .loading-img,
.overlay-wrapper > .loading-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.box .overlay,
.overlay-wrapper .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
}
.box .overlay > .fa,
.overlay-wrapper .overlay > .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}
.box .overlay.dark,
.overlay-wrapper .overlay.dark {
  background: rgba(0, 0, 0, 0.5);
}
.box-header:before,
.box-body:before,
.box-footer:before,
.box-header:after,
.box-body:after,
.box-footer:after {
  content: " ";
  display: table;
}
.box-header:after,
.box-body:after,
.box-footer:after {
  clear: both;
}
.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}
.box-header.with-border {
  border-bottom: 1px solid #f4f4f4;
}
.collapsed-box .box-header.with-border {
  border-bottom: none;
}
.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion,
.box-header .box-title {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
}
.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion {
  margin-right: 5px;
}
.box-header > .box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
}
.box-header > .box-tools [data-toggle="tooltip"] {
  position: relative;
}
.box-header > .box-tools.pull-right .dropdown-menu {
  right: 0;
  left: auto;
}
.btn-box-tool {
  padding: 5px;
  font-size: 12px;
  background: transparent;
  color: #97a0b3;
}
.open .btn-box-tool,
.btn-box-tool:hover {
  color: #606c84;
}
.btn-box-tool.btn:active {
  box-shadow: none;
}
.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
}
.no-header .box-body {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.box-body > .table {
  margin-bottom: 0;
}
.box-body .fc {
  margin-top: 5px;
}
.box-body .full-width-chart {
  margin: -19px;
}
.box-body.no-padding .full-width-chart {
  margin: -9px;
}
.box-body .box-pane {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
}
.box-body .box-pane-right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
}
.box-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  /* border-top: 1px solid #f4f4f4;*/
  padding: 10px;
  background-color: transparent;
}
.direct-chat .box-body {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  overflow-x: hidden;
  padding: 0;
}
.direct-chat.chat-pane-open .direct-chat-contacts {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.direct-chat-messages {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  padding: 10px;
  height: 100%;
  overflow: auto;
}
.direct-chat-msg,
.direct-chat-text {
  display: block;
}
.direct-chat-msg {
  margin-bottom: 10px;
}
.direct-chat-msg:before,
.direct-chat-msg:after {
  content: " ";
  display: table;
}
.direct-chat-msg:after {
  clear: both;
}
.direct-chat-messages,
.direct-chat-contacts {
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  -moz-transition: -moz-transform 0.5s ease-in-out;
  -o-transition: -o-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}
.direct-chat-text {
  border-radius: 5px;
  position: relative;
  padding: 5px 10px;
  background: #d2d6de;
  border: 1px solid #d2d6de;
  margin: 5px 0 0 50px;
  color: #444;
}
.direct-chat-text:after,
.direct-chat-text:before {
  position: absolute;
  right: 100%;
  top: 15px;
  border: solid transparent;
  border-right-color: #d2d6de;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
}
.direct-chat-text:after {
  border-width: 5px;
  margin-top: -5px;
}
.direct-chat-text:before {
  border-width: 6px;
  margin-top: -6px;
}
.right .direct-chat-text {
  margin-right: 50px;
  margin-left: 0;
}
.right .direct-chat-text:after,
.right .direct-chat-text:before {
  right: auto;
  left: 100%;
  border-right-color: transparent;
  border-left-color: #d2d6de;
}
.direct-chat-img {
  border-radius: 50%;
  float: left;
  width: 40px;
  height: 40px;
}
.right .direct-chat-img {
  float: right;
}
.direct-chat-info {
  display: block;
  margin-bottom: 2px;
  font-size: 12px;
}
.direct-chat-name {
  font-weight: 600;
  color: #fff;
}
.direct-chat-timestamp {
  color: #999;
}
.direct-chat-contacts-open .direct-chat-contacts {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.direct-chat-contacts {
  -webkit-transform: translate(101%, 0);
  -ms-transform: translate(101%, 0);
  -o-transform: translate(101%, 0);
  transform: translate(101%, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  height: 250px;
  width: 100%;
  background: #222d32;
  color: #fff;
  overflow: auto;
}
.contacts-list > li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin: 0;
}
.contacts-list > li:before,
.contacts-list > li:after {
  content: " ";
  display: table;
}
.contacts-list > li:after {
  clear: both;
}
.contacts-list > li:last-of-type {
  border-bottom: none;
}
.contacts-list-img {
  border-radius: 50%;
  width: 40px;
  float: left;
}
.contacts-list-info {
  margin-left: 45px;
  color: #fff;
}
.contacts-list-name,
.contacts-list-status {
  display: block;
}
.contacts-list-name {
  font-weight: 600;
}
.contacts-list-status {
  font-size: 12px;
}
.contacts-list-date {
  color: #aaa;
  font-weight: normal;
}
.contacts-list-msg {
  color: #999;
}
.direct-chat-danger .right > .direct-chat-text {
  background: #dd4b39;
  border-color: #dd4b39;
  color: #fff;
}
.direct-chat-danger .right > .direct-chat-text:after,
.direct-chat-danger .right > .direct-chat-text:before {
  border-left-color: #dd4b39;
}
.direct-chat-primary .right > .direct-chat-text {
  background: #3c8dbc;
  border-color: #3c8dbc;
  color: #fff;
}
.direct-chat-primary .right > .direct-chat-text:after,
.direct-chat-primary .right > .direct-chat-text:before {
  border-left-color: #3c8dbc;
}
.direct-chat-warning .right > .direct-chat-text {
  background: #f39c12;
  border-color: #f39c12;
  color: #fff;
}
.direct-chat-warning .right > .direct-chat-text:after,
.direct-chat-warning .right > .direct-chat-text:before {
  border-left-color: #f39c12;
}
.direct-chat-info .right > .direct-chat-text {
  background: #00c0ef;
  border-color: #00c0ef;
  color: #fff;
}
.direct-chat-info .right > .direct-chat-text:after,
.direct-chat-info .right > .direct-chat-text:before {
  border-left-color: #00c0ef;
}
.direct-chat-success .right > .direct-chat-text {
  background: #fff;
  border-color: #fff;
  color: #000;
}
.direct-chat-success .right > .direct-chat-text:after,
.direct-chat-success .right > .direct-chat-text:before {
  border-left-color: #fff;
}

.banner-btn, .secondary-btn {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  background: transparent;
  padding: 18px 21px;
  transition: 0.3s linear;
  color: #fff;
  line-height: 1;
}
.banner-btn:hover {
  color: #fff;
}
.banner-btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 51px;
  height: 51px;
  background: var(--nft-gradient-color);
  z-index: -1;
  border-radius: 30px;
  transition: 0.3s linear;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.banner-btn:hover::after {
  width: 100%;
}
.banner-btn i {
  line-height: 0;
  margin-left: 12px;
}
.secondary-btn i {
  line-height: 0;
  margin-left: 12px;
}
.secondary-btn:hover {
  color: #fff;
}
.secondary-btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 51px;
  height: 51px;
  background: var(--nft-gradient-color);
  z-index: -1;
  border-radius: 30px;
  transition: 0.3s linear;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.secondary-btn:hover::after {
  width: 100%;
}

.sidebar-icon{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  border-radius: 9px;
  border: 1px solid #2a2547;
}

.pagination {
  display: flex;
  position: relative;
  list-style-type: none;
}

.pagination .page-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  margin: 0 10px;
  height: 30px;
  width: 30px;
  background: none;
  border: 2px solid #2095e5;
  color: #ffffff; 
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  user-select: none;
}
.pagination .page-item .page-link{
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
}

.pagination .active {
  background-color: #2095e5;
  border-color: transparent;
  color: white;
}

.trade-record-card {
  background: var(--sidebar-icon-background);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  border-radius: 9px;
  border: 1px solid var(--icon-border-color);
  transition: all 0.3s ease-in 0s;
  border-radius: 9px;
  opacity: 0.9;
  color: #fff;
  font-size: 12px;
}

.FormStepList {
  display: flex;
  overflow: hidden;
  margin: 0em auto;
  width: 100%;
}
.FormStepList-item {
  flex: 1 1 50px;
  text-align: center;
  list-style: none;
}
.FormStepList-item:before {
  content: none !important;
  display: none !important;
}
.FormStepIcon {
  display: inline-block;
  width: 100px;
  text-align: center;
  position: relative;
}
.FormStepIcon.is-active .FormStepIcon-circle {
  background: var(--nft-gradient-color);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  border: 4px solid var(--icon-border-color);
  color: #fff;
}
.FormStepIcon.is-passed:after {
  background: var(--gradient-btn-hover);
}
.FormStepIcon.is-passed .FormStepIcon-circle {
  background: var(--gradient-btn-hover);
  color: #fff;
}
.FormStepIcon:after {
  content: "";
  background: #d4d4d4;
  position: absolute;
  right: -1000%;
  top: 20px;
  left: 10px;
  height: 4px;
  z-index: 1;
}
.FormStepIcon.FormStepIcon--last:after {
  background: #bfbfbf;
}
.FormStepIcon-circle {
  background: #d4d4d4;
  color: #a9a9a9;
  font-size: 16px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
.FormStepIcon-title {
  display: block;
  font-size: 0.7em;
  width: 70px;
  margin-top: 0.5em;
}
